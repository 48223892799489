/*
font-family: 'Raleway', sans-serif;
font-family: 'Philosopher', sans-serif;
font-family: 'Open Sans', sans-serif;
*/


/*ProximaNova*/

@font-face {
    font-family: 'proxima_nova_blblack';
    src: url('../fonts/proxima/ProximaNova-Black-appj.eot');
    src: url('../fonts/proxima/ProximaNova-Black-appj.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNova-Black-appj.woff2') format('woff2'),
         url('../fonts/proxima/ProximaNova-Black-appj.woff') format('woff'),
         url('../fonts/proxima/ProximaNova-Black-appj.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url('../fonts/proxima/ProximaNova-Bold-appj.eot');
    src: url('../fonts/proxima/ProximaNova-Bold-appj.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNova-Bold-appj.woff2') format('woff2'),
         url('../fonts/proxima/ProximaNova-Bold-appj.woff') format('woff'),
         url('../fonts/proxima/ProximaNova-Bold-appj.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_nova_ltlight';
    src: url('../fonts/proxima/ProximaNova-Light-appj.eot');
    src: url('../fonts/proxima/ProximaNova-Light-appj.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNova-Light-appj.woff2') format('woff2'),
         url('../fonts/proxima/ProximaNova-Light-appj.woff') format('woff'),
         url('../fonts/proxima/ProximaNova-Light-appj.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url('../fonts/proxima/ProximaNova-Reg-appj.eot');
    src: url('../fonts/proxima/ProximaNova-Reg-appj.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNova-Reg-appj.woff2') format('woff2'),
         url('../fonts/proxima/ProximaNova-Reg-appj.woff') format('woff'),
         url('../fonts/proxima/ProximaNova-Reg-appj.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_nova_ltsemibold';
    src: url('../fonts/proxima/ProximaNova-Sbold-appj.eot');
    src: url('../fonts/proxima/ProximaNova-Sbold-appj.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNova-Sbold-appj.woff2') format('woff2'),
         url('../fonts/proxima/ProximaNova-Sbold-appj.woff') format('woff'),
         url('../fonts/proxima/ProximaNova-Sbold-appj.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_nova_ththin';
    src: url('../fonts/proxima/ProximaNova-Thin-appj.eot');
    src: url('../fonts/proxima/ProximaNova-Thin-appj.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNova-Thin-appj.woff2') format('woff2'),
         url('../fonts/proxima/ProximaNova-Thin-appj.woff') format('woff'),
         url('../fonts/proxima/ProximaNova-Thin-appj.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_nova_thextrabold';
    src: url('../fonts/proxima/ProximaNova-Xbold-appj.eot');
    src: url('../fonts/proxima/ProximaNova-Xbold-appj.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNova-Xbold-appj.woff2') format('woff2'),
         url('../fonts/proxima/ProximaNova-Xbold-appj.woff') format('woff'),
         url('../fonts/proxima/ProximaNova-Xbold-appj.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
