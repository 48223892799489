/*!
 * Lightbox for Bootstrap 3 by @ashleydw
 * https://github.com/ashleydw/lightbox
 *
 * License: https://github.com/ashleydw/lightbox/blob/master/LICENSE
 */

.ekko-lightbox-container {
position:relative;
}

.ekko-lightbox-container>div.ekko-lightbox-item {
position:absolute;
top:50%;
left:50%;

  transform: translate(-50%, -50%);
    
width:100%;
cursor: pointer;
}

.ekko-lightbox iframe {
width:100%;
height:100%;
}

.ekko-lightbox
{
  background-color: #fff;
  height: calc(100% - 100px);
  top:70px;
  padding: 0!important;
  overflow: hidden!important;
}


.ekko-lightbox-nav-overlay {
z-index:1;
position:absolute;
top:0;
left:0;
width:100%;
height:100%;
display:flex;
}

.ekko-lightbox-nav-overlay a {
-ms-flex:1;
flex:1;
display:flex;
-ms-flex-align:center;
align-items:center;
opacity:0;
transition:opacity .5s;
color:#fff;
font-size:30px;
z-index:1;
}

.ekko-lightbox-nav-overlay a>* {
-ms-flex-positive:1;
flex-grow:1;
}

.ekko-lightbox-nav-overlay a span {
padding:0 30px;
}

.ekko-lightbox-nav-overlay a:last-child span {
text-align:right;
}

.ekko-lightbox-nav-overlay a:hover {
text-decoration:none;
}

.ekko-lightbox a:hover {
opacity:1;
text-decoration:none;
}




.ekko-lightbox .modal-body {
  padding: 0;
}


.ekko-lightbox .modal-content {
  border-radius: 0px;
  border: 1px solid #fff;
}


.ekko-lightbox .modal-dialog {
display:none;
margin: 0 auto;
text-align: center;

}

.ekko-lightbox .modal-footer {
text-align:left;
}

.ekko-lightbox-loader {
position:absolute;
top:0;
left:0;
bottom:0;
right:0;
width:100%;
display:flex;
-ms-flex-direction:column;
flex-direction:column;
-ms-flex-pack:center;
justify-content:center;
-ms-flex-align:center;
align-items:center;
}


.ekko-lightbox-loader .loader
{
  width: 40px;
  margin: 0 auto;
  animation: spin 2s infinite linear;
}

/*
.ekko-lightbox-loader>div {
width:40px;
height:40px;
position:relative;
text-align:center;
}

.ekko-lightbox-loader>div>div {
width:100%;
height:100%;
border-radius:50%;
background-color:#fff;
opacity:1;
position:absolute;
top:0;
left:0;
animation:a 2s infinite ease-in-out;
}
*/

.ekko-lightbox-loader>div>div:last-child {
animation-delay:-1s;
}

.modal-dialog .ekko-lightbox-loader>div>div {
background-color:#333;
}

50% {
transform:scale(1);
-webkit-transform:scale(1);
}

.ekko-lightbox-nav-overlay a>:focus,.ekko-lightbox-nav-overlay a:focus {
outline:none;
}


.modal-backdrop
{
  background-color: #fff!important;
  opacity: 1!important;
}